import paper from 'paper'

class DepixoPaper {
  constructor(project, size){
		this._project = project
    this._size = new paper.Size(size)


    this._history = {
      now : null,
      backward: [],
      forward: []
    }

    this._hud = this._project.layers['hud']
    this._hudStartPoint = this._hud.children['start-point']
    this._hudCloseWarn = this._hud.children['close-warn']

    //this.initPointerEvents()
    //this.testTool()
  }
  // testTool(){
  //   this._tool = new paper.Tool()
  //
  //   this._tool.onMouseDown = evt => this._start(evt)
  //   this._tool.onMouseDrag = evt => this._move(evt)
  //   this._tool.onMouseUp = evt => this._end(evt)
  //
  //   this._tool.activate()
  // }
  //
  // initPointerEvents(){
  //   const options = {capture: true, passive: true}
  //   const target = this._project.view.element
  //   target.addEventListener('pointerdown', (evt) => {this._pointerStart(evt)}, options)
  //   target.addEventListener('pointermove', (evt) => {this._pointerMove(evt)}, options)
  //   target.addEventListener('pointerup', (evt) => {this._pointerEnd(evt)}, options)
  //   target.addEventListener('pointercancel', (evt) => {this._pointerEnd(evt)}, options)
  // }

  _start(evt){

    const boundPoint = this._getBoundPoint(evt.point)
    this._hudStartPoint.visible = true
    this._hudStartPoint.position = evt.point

    this.enableFill(false)

    this._history.startPoint = boundPoint
    this._history.now = this._workingPath()
    this._history.now.add(boundPoint)
  }

  _move(evt){
    if (!this._history.now) return
    const boundPoint = this._getBoundPoint(evt.point)
    const isBackAtStart = !!this._hudStartPoint.hitTest(evt.point) && this._history.now.segments.length >= 40

    this._history.now.add(boundPoint)

    if(isBackAtStart){
      this._hudCloseWarn.visible = true
      this._hudCloseWarn.position = evt.point
    }else{
      this._hudCloseWarn.visible = false
    }
  }

  _end(evt){
    if (!this._history.now) return
    const boundPoint = this._getBoundPoint(evt.point)
    const isBackAtStart = !!this._hudStartPoint.hitTest(evt.point) && this._history.now.segments.length >= 40
    const isMinimum = this._history.now.segments.length >= 5

    this._hudStartPoint.visible = false
    this._hudCloseWarn.visible = false
    this._history.now.add(boundPoint)

    if (this._history.now.length <= 3 ){

      this._history.now = this._circle(this._history.startPoint)
    }else{
      this.enableFill(true)

      if(isBackAtStart){
				this._history.now.closed = true
        this._history.now.originallyClosed = true

				this._history.now.add(this._history.now.firstSegment.point)
				this._history.now.fillColor = this._getColorOrWhite()
        this.enableFill(true, true)
			}
      if (isMinimum){
        this._history.now.smooth({ type:'continuous' })
        this._history.now.simplify(5)
      }
    }
    if (!!this._history.now){
      this._history.backward.push(this._history.now)
      this._history.forward = []
      this.updateUISaveState('save')
      //console.log(this._history.now.parent, this._history.now.data.originalParent);
      this._history.now.parent = this._history.now.originalParent
    }

    this.updateRaster()
  }

  updateRaster(){
    return
    this.org.drawingGroup.visible = true
    const b = this._project.view.bounds;
    const zoom = this._project.view.zoom
    const magnifyBy = 1 / zoom
    const pr = this._project.view.pixelRatio

    const r = new paper.Rectangle(b.x, b.y, b.width*pr, b.height*pr)

    const raster = this.org.drawingGroup.rasterize(this._project.view.resolution * zoom, false)
    raster.smoothing = false;

    //const subRaster = raster.getSubRaster(r)
    //const subCanvas = raster.getSubCanvas(r);
    //const subRaster = new paper.Raster(subCanvas)

    const first = this.org.rasterGroup.firstChild
    if(!! first)
      first.remove()

    this.org.rasterGroup.addChild(raster)
    this.org.drawingGroup.visible = false
  }

  selfParenting(){
    this._parent = this;
  }

  cancelDraw(){
    if(!!this._history.now)this._history.now.remove()
    this._history.now = null
    this._hudStartPoint.visible = false
    this._hudCloseWarn.visible = false

    // this.resetFill()
  }

  _pointerStart(evt){
    if (!evt.isPrimary){
      this.cancelDraw()
      return
    }
    console.log('starting');
    evt.target.setPointerCapture(evt.pointerId)
    const newEvent = this._translateEvent(evt)
    this._start(newEvent)
  }
  _pointerMove(evt){
    if (evt.buttons === 0 || !evt.isPrimary) return

    const newEvent = this._translateEvent(evt)
    this._move(newEvent)
  }
  _pointerEnd(evt){
    if (!evt.isPrimary) return
    evt.target.releasePointerCapture(evt.pointerId)
    const newEvent = this._translateEvent(evt)
    this._end(newEvent)
  }

  _translateEvent(evt){
    const newEvent = {
      point: this._project.view.viewToProject([evt.offsetX,evt.offsetY])
    }
    return newEvent
  }

  _getBoundPoint(point){
    point = point.clone()
      const expandBy = 6
      point.x = point.x < -expandBy ? -expandBy : point.x
      point.x = point.x > this._size.width + expandBy ? this._size.width + expandBy : point.x
      point.y = point.y < -expandBy ? -expandBy : point.y
      point.y = point.y > this._size.height + expandBy ? this._size.height + expandBy : point.y

    return point
  }

  set erase(isErase){
    this._isErase = isErase
  }

  toggleFill(){
    const item = this._history.backward[this._history.backward.length -1]
    let filled = item.toggleFill()
    this.updateRaster()
    this.updateUI.filled(filled)

    //console.log(filled)
    //return filled
  }

  updateUISaveState(saveState){
    this.updateUI.saveButton(saveState)
  }

  updateUIColor(colorID){
    this.updateUI.setColorID(colorID)
  }

  enableFill(buttonEnabled, buttonFilled){
    this.updateUI.fillEnabled(buttonEnabled)
    this.updateUI.filled(buttonFilled)
  }

  set colorID(colorID){
    this._colorID = colorID
  }

  _getColorOrWhite(){
    return this._isErase ? 'white' : this._project.currentStyle.strokeColor
  }

  _getColorGroup(){
    const groupName = 'colorId' + this._colorID
    const alreadyGroup = this.org.drawingGroup.children[groupName]

    console.log(this._project.layers);
    let group

    if(!!alreadyGroup){
      console.log('alreadyGroup');
      group = alreadyGroup
    }else{
      console.log('createGroup');
      group = new paper.Group({
        name: groupName,
        blendMode: 'multiply',
        parent: this.org.drawingGroup,
        fillColor: this._project.currentStyle.strokeColor,
      })

    }
    console.log(group.parent);
    return group
  }

  //////////////////////////////////////////////////////////////////////////////////////////////
  // History Handlers   ////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////////

  historyBackward(){
    const last = this._history.backward.pop()

    if (!!last){
      this._history.forward.push(last)
      last.remove()
      this.resetFill()
    }
    console.log('UNdo, back: ',this._history.backward.length,'fore: ',this._history.forward.length)
    this.updateRaster()
  }

  historyForward(){
    const last = this._history.forward.pop()

    //console.log('REdo, forward length: ',this._history.forward.length)
    if (!!last){
      this._history.backward.push(last)
      last.originalParent.addChild(last)
      this.resetFill()
    }
    this.updateRaster()
    console.log('REdo, back: ',this._history.backward.length,'fore: ',this._history.forward.length)
  }

  resetFill(){
    const current = this._history.backward[this._history.backward.length -1]
    if (!!current){
      const filled = (!!current.fillColor)
      const enabled = (!!current.fillable)
      this.enableFill(enabled,filled)
    }else{
      this.enableFill(false)
    }
  }

  //////////////////////////////////////////////////////////////////////////////////////////////
  /////////////                     ////////////////////////////////////////////////////////////
  /////////////    Path Templates   ////////////////////////////////////////////////////////////
  /////////////                     ////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////////

  _circle(centerPoint){
    const parent = this.org.strokeGroup
    const circle = new paper.Path.Circle({
      parent: parent,
      strokeColor: null,
      strokeWidth: 0,
      fillColor: this._getColorOrWhite(),
      radius: this._project.currentStyle.strokeWidth / 2,
      center: centerPoint,

        originalParent: this._getColorGroup(),
        originallyClosed: true,
        fillable: false,

      toggleFill:function(){return false},
    })
    return circle
  }

  _workingPath(){
    const parent = this.org.strokeGroup
    const workingPath = new paper.Path({
      parent: parent,
      strokeColor: this._getColorOrWhite(),
      strokeWidth: this._project.currentStyle.strokeWidth,

        originalParent: this._getColorGroup(),
        originallyClosed: false,
        fillable: true,

      toggleFill:function() {
        this.fillColor = this.fillColor ? null : this.strokeColor
        this.closed = this.fillColor ? true : false
        if (!this.fillColor && this.originallyClosed){
          this.closed = true
        }
        return !!this.fillColor
      }
    })
    parent.blendMode = this._isErase ? 'normal' : 'multiply'
    parent.opacity = this._isErase ? .5 : 1
    return workingPath
  }
}

export default DepixoPaper
