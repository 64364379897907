import React, {useState, useEffect, useLayoutEffect, useReducer} from 'react'
import PaperCanvas from './PaperCanvas'
import Palette from './Palette'
import FillButton from './FillButton'
import SaveButton from './SaveButton'
//import paletteData from './testdata/palette.js'
import Depixo from '../Depixo/Depixo'
import useFetch from './useFetch'
import {useParams} from "react-router-dom"
//import {knight} from './testdata/knight.js'


import '../icons.css'
let depixo = null

const swatchReducer = (state, target) => {
  const newState = target
  return newState
}


const Drawing = props => {
  const { id } = useParams()
  const { data, isLoading, isError } = useFetch(
    "https://alpha.depixo.com/fetch/getdrawing.php",
  {code:id ||''}
  // const { data, isLoading, isError } = useFetch(
  //   "/fetch/defaultPalette.json",
  // {code:id ||''}
)

  const [colorID, setColorID] = useState(1)
  const [isFilled, setIsFilled] = useState(false)
  const [isFillEnabled, setIsFillEnabled] = useState(false)
  const [saveButtonState, setSaveButtonState] = useState('disabled')

  const [paletteState, setPaletteState] = useReducer(swatchReducer, [{id:1, isSelected:true},{id:2},{id:3},{id:4},{id:5},{id:6},{id:7},{id:8}])

  //RUN ONCE After DOM
  useLayoutEffect(() => {
    const updateUI = {
      fillEnabled: setIsFillEnabled,
      filled: setIsFilled,
      saveButton: setSaveButtonState,
      setColorID: setColorID
    }
    depixo = new Depixo('canvas',updateUI)

  },[])

  useEffect(() => {
    if(!!data){
      depixo.open(data)
      setPaletteState(data.palette)
      //setColorID(1)

    }
  },[data, isError, isLoading])

  useEffect(() => {

  },[colorID])

  function handleSwatchClick(swatchID){

    const newState = paletteState.map((swatch,index) => {
      if (swatch.id === swatchID){
        swatch.isErase = !swatch.isSelected ? false : !swatch.isErase
        swatch.isSelected = true

        if(!!depixo){
          depixo.erase = swatch.isErase
          depixo.colorID = swatch.id
        }

      }else{
        swatch.isErase = false
        swatch.isSelected = false
      }

      return swatch
    })

    setPaletteState(newState)
  }

  function saveDrawingHandler(){
    console.log(depixo.getSaveData())
    setSaveButtonState('working')
  }

  function toggleFill(){
    depixo.toggleFill()
  }

  return(
    <div className='page'>
      <span>{}</span>
      <PaperCanvas />

      <div className="toolbox">
        <Palette
        palette={paletteState}
        handleSwatchClick={handleSwatchClick}
        />

        <div className="flex-item fill-container">
    			<FillButton
          isFilled={isFilled}
          isFillEnabled={isFillEnabled}
          fillHandler={toggleFill}
          />
    		</div>

    		<div className="flex-item save-container">
          <SaveButton
          saveDrawingHandler={saveDrawingHandler}
          saveButtonState={saveButtonState}
          />
    		</div>

      </div>

    </div>
  )
}
export default Drawing
