
import { useState, useEffect } from "react";

function useFetch(url, dataTosend){
  const [state, setState] = useState({
    isLoading: true,
    isError: false,
    data: null,
  });

  useEffect(() => {
    fetch(url,{
      method: 'POST',
      //mode: 'no-cors',
      body: !!dataTosend ? JSON.stringify(dataTosend) : null
    })
      .then(res => {
        if (!res.ok) {
          throw new Error(res.status);
        }
        return res.json();
      })
      .then(data => setState({ isLoading: false, isError: false, data }))
      .catch(error => setState({ isLoading: false, isError: error, data: [] }))
  },[url])

  return state
}
export default useFetch
