import React from 'react'
import ColorSwatch from './ColorSwatch'

function Palette(props){

  //console.log(paletteState)
  const swatches = props.palette.map(
    swatch =>
    <ColorSwatch
    handleSwatchClick={() => {props.handleSwatchClick(swatch.id)}}
    isSelected={swatch.isSelected || false}
    isErase={swatch.isErase || false}
    key={swatch.id}
    color={swatch.color}
    />)
    return(
      <div id='color-palette' className='flex-container'>
        {swatches}
      </div>
  )
}

export default Palette
