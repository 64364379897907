import React from 'react'

function Header(){
  return(
    <header>
      <h1>Depixo</h1>
    </header>
  )
}
export default Header
