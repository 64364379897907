import React from 'react'

function FillButton(props){
  const classNames = ['dot', 'mutable']

  if (props.isFillEnabled){
    classNames.push('enabled')
  }
  if (props.isFilled){
    classNames.push('filled')
  }

  return(
    <button
    onClick={props.fillHandler}
    id="button-fill-toggle"
    className={classNames.join(' ')}
    >
    <div></div>
    </button>
  )
}
export default FillButton
