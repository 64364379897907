import React from 'react'
function PaperCanvas(props){

  return (
    <div className="canvas-contain">
      <canvas id="canvas" hidpi="on" className="" resize="" stats="" width="1080" height="1080"></canvas>
    </div>
  )

}
export default PaperCanvas
