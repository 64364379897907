import paper from 'paper'
//import DepixoEvents from './DepixoEvents'

class DepixoView{
  constructor(project, size){
    this._project = project
    this._hud = this._project.layers['hud']
    this._hudZoomIndicator = this._hud.children['zoom-indicator']
    this._hudZoomGrid= this._hud.children['zoom-grid']
    this._hudStartPoint = this._hud.children['start-point']
    this._hudCloseWarn = this._hud.children['close-warn']


    this._clippingMasks = []
    //this._offscreenCanvas = offscreen

    this._view = this._project.view
    this._size = size


    this._maxZoom = 1.2 //1.2 was max before the canvas starts to break on safari
    this._minZoom = 0.8

    this._zoomInit = this._view.size.width/this._size.width
		this._centerInit = [this._size.width/ 2 , this._size.height /2 ]
    this._zoomView(this._zoomInit,this._centerInit)

    this._view.onResize = evt => { this._resizeCanvasHandler(evt) }
  }

  //////////////////////////////////////////////////////////////////////////////////////////////
  // Zoom Handlers      ////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////////

  pinchStart(evt){
    this._zoomStartCenter = this._view.center
    this._zoomStartZoom = this._view.zoom

  }

  pinchMove(evt){
    const deltaAsPoint = [evt.deltaX,evt.deltaY]
    let viewScale = this._zoomStartZoom * evt.scale

    const viewCenter = this._zoomStartCenter.add(
      deltaAsPoint.map(x => x * this._magnify * -1))

    this._hudZoomGrid.visible = true
    this._zoomView(viewScale, viewCenter)
  }

  pinchEnd(evt){
    this.updateRaster()
    this._hudZoomGrid.visible = false
    if(evt.deltaTime < 200 && evt.scale < .7 && this._zoomStartZoom !== this._zoomInit) this.resetView()
    this.pinchEndCallback(this._magnify)
  }

  pinchEndCallback(resizeFactor){
    ///Replaced By callback function
  }

  resetView(){
    this._zoomView(this._zoomInit, this._centerInit)
  }

  _resizeCanvasHandler(evt){
    const zoom = evt.size.width/this._size.width
    const center = this._centerInit
    this._zoomInit = zoom
    this._zoomView(zoom, center)
  }

  _zoomView(zoom, center = this._centerInit){

    if(zoom > this._maxZoom){
      zoom = this._maxZoom
    }else if(zoom < this._zoomInit * this._minZoom){
      zoom = this._zoomInit * this._minZoom
    }

    if(zoom < this._zoomInit){
      center = this._centerInit
    }

    this._magnify = 1 / zoom
    this._view.center = center
    this._view.zoom = zoom

    console.log(zoom);
    this._adjustUI()

  }
  _adjustUI(){
    ////THIS is probably unnecessary

    //// End unnecessary
    ////
     if (this._view.zoom < this._zoomInit){
       this._clippingMasks.forEach(mask => {mask.fitBounds(this._project.activeLayer.children['background'].bounds,true)})
     }else{
       this._clippingMasks.forEach(mask => {mask.fitBounds(this._view.bounds,true)})
     }

    this._hudStartPoint.setScaling(this._magnify)
    this._hudCloseWarn.setScaling(this._magnify)

    this._hudZoomIndicator.fitBounds(this._view.bounds,true)
    this._hudZoomIndicator.visible = (this._view.zoom - .00000000000001 >= this._zoomInit) ? true : false

    this._project._scope.settings.hitTolerance = 10
    this._hudZoomGrid.strokeWidth = 0.5 * this._magnify
  }

}

export default DepixoView
