import React from 'react'

function ColorSwatch (props){
  const classNames = ['dot', 'color']
  if (props.isSelected){
    classNames.push('active')
  }
  if (props.isErase){
    classNames.push('erase')
  }

    return(
        <button
          onClick={props.handleSwatchClick}
          className={classNames.join(' ')}
          color={props.color}
          style={{backgroundColor: props.color}}
        >
        <div></div>
        </button>
    )
}
export default ColorSwatch
