import React from 'react'
import {
  BrowserRouter as
  Router,
  Switch,
  Route
} from "react-router-dom"
import Header from './components/Header'
import Drawing from './components/Drawing'
//import DrawingNew from './components/DrawingNew'
import Footer from './components/Footer'
//import PaperCanvas from './components/PaperCanvas'
import './App.css'


function App() {
  return (
    <div className="App">
    <Switch>

      <Route path="/edit">
        <Header />
        <Drawing />
        <Footer />
      </Route>

      <Route path="/test">
        <Header />
        <Drawing />
        <Footer />
      </Route>

      <Route path="/d/:id">
        <Drawing />
      </Route>

      <Route path="/">

        <Drawing />

      </Route>

    </Switch>
    </div>
  )
}

export default App
