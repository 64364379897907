import React from 'react'

function SaveButton(props){
  const classNames = ['dot', 'mutable']

	switch (props.saveButtonState) {
    case 'disabled':
      break

		case 'working':
      classNames.push('working')
      classNames.push('enabled')
			break

		case 'send':
      classNames.push('icon-send')
      classNames.push('enabled')
			break

		case 'save':
      classNames.push('icon-checkmark')
      classNames.push('enabled')
      break
		default:

	}
  function clickHandler(event){
    props.saveDrawingHandler()
  }

  return(
    <button onClick={clickHandler} id="button-save" className={classNames.join(' ')}></button>
  )
}
export default SaveButton
