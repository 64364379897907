import paper from 'paper'
import DepixoView from './DepixoView'
import DepixoPaper from './DepixoPaper'
import Hammer from 'hammerjs'


class Depixo{
	constructor(canvasID,updateUIFunctions){
		this.updateUI = updateUIFunctions
		const size = [1080, 1080]

		const canvas =  document.getElementById(canvasID)
		this._project = new paper.Project(canvas)
		this._project.activate()
    this._size = new paper.Size(size)
		this._palette = []

		this._depixoFile = {
			parentID: 0,
			paletteID: null,
			layer: null
		}

		this._veiwMasks = []

		this._hudStyle = {
			color: '#64b7e7'
		}

		this._project.currentStyle = {
			strokeCap: 'round',
			strokeJoin: 'round',
			strokeWidth: 10,
			//strokeColor: 'pink',
			fillColor: null,
		}

		//Project Hierarchy
		this._workingLayer = this._project.activeLayer
		this._workingLayer.name = 'workinglayer'

		const mainClip= new paper.Path.Rectangle({
			name: 'mainclip',
      point: [0,0],
      size: this._project.view.size,
      applyMatrix: false,
			clipMask: true
    }).sendToBack()

		this._veiwMasks.push(mainClip)

		new paper.Path.Rectangle({
			name: 'background',
      point: [0,0],
      size: this._size,
			fillColor: 'white',
			strokeColor: null
    })
		this.org = {
			clipLayer: this._project.activeLayer,
				importGroup: new paper.Group(),
				rasterGroup: new paper.Group({blendMode:'multiply'}),
				drawingGroup: new paper.Group(),
				strokeGroup: new paper.Group({blendMode:'multiply'}),

			hud: new paper.Layer({name: 'hud'}),
		}

		this._buildHUD(this.org.hud)
		this.org.clipLayer.activate()
		this._enableViewControl()
		this._enableEdit()
	}

	set colorID(colorID){
		const color = this._palette.find(entry => entry.id === colorID)
		this._colorID = colorID

		//console.log('this Color',color.color);
		if (!!this._draw) this._draw.colorID = colorID
		this._project.currentStyle.strokeColor = color.color
	}

	get colorID(){
		return this._colorID
	}

	set erase(isErase){
		if(!!this._draw) this._draw.erase = isErase
	}

	toggleFill(){
		if(!!this._draw) return this._draw.toggleFill()

	}

	updateRaster(){
		return
    this.org.drawingGroup.visible = true
    //const b = this._project.view.bounds;
    const zoom = this._project.view.zoom
    //const magnifyBy = 1 / zoom
    //const pr = this._project.view.pixelRatio

    //const r = new paper.Rectangle(b.x, b.y, b.width*pr, b.height*pr)

    const raster = this.org.drawingGroup.rasterize(this._project.view.resolution * zoom, false)
    raster.smoothing = false;

    //const subRaster = raster.getSubRaster(r)
    //const subCanvas = raster.getSubCanvas(r);
    //const subRaster = new paper.Raster(subCanvas)

    const first = this.org.rasterGroup.firstChild
    if(!! first)
      first.remove()

    this.org.rasterGroup.addChild(raster)
    this.org.drawingGroup.visible = false
  }

	_enableViewControl(){
		this._viewControl = new DepixoView(this._project, this._size)
		this._viewControl.org = this.org
		this._viewControl._clippingMasks = this._veiwMasks
		this._viewControl.updateRaster = this.updateRaster
		this._viewControl.resetView()
		//Setup Event Handlers
		//this.events = new Hammer(this._project.view.element)
		this.events = new Hammer(document)
		//this.events.options.domEvents=true; // enable dom events
		this.events.get('pinch').set({ enable: true, threshold:0.02})
		this.events.on('pinchstart', evt => {
			this._viewControl.pinchStart(evt)
			this._draw.cancelDraw()
		})
		this.events.on('pinchmove', evt => this._viewControl.pinchMove(evt))
		this.events.on('pinchend', evt => this._viewControl.pinchEnd(evt))
	}

	_enableEdit(){
		this._draw = new DepixoPaper(this._project, this._size, this._palette)
		if(!!this.updateUI) this._draw.updateUI = this.updateUI
		this._draw.org = this.org
		this._draw.updateRaster = this.updateRaster
		const options = {capture: true, passive: true}
    const target = this._project.view.element
    target.addEventListener('pointerdown', (evt) => {this._draw._pointerStart(evt)}, options)
    target.addEventListener('pointermove', (evt) => {this._draw._pointerMove(evt)}, options)
    target.addEventListener('pointerup', (evt) => {this._draw._pointerEnd(evt)}, options)
    target.addEventListener('pointercancel', (evt) => {this._draw.cancelDraw(evt)}, options)

		//this.events.add(new Hammer.Tap({ event: 'onefingertap', pointers: 1 ,time: 300, threshold: 100}))
		//this.events.add(new Hammer.Tap({ event: 'twofingertap', pointers: 2 ,time: 300, threshold: 100}))
		//this.events.add(new Hammer.Tap({ event: 'threefingertap', pointers: 3, time: 300, threshold:400}))
		this.events.get('tap').set({ pointers: 1, threshold:100 })


		this.events.on('tap', evt => {
			//console.log(evt.maxPointers)
			if(evt.maxPointers > 1){
				this._draw.cancelDraw()
			}
			if(evt.maxPointers === 2) this._draw.historyBackward()
			if(evt.maxPointers === 3) this._draw.historyForward()
		})
	}

	open(depixoFile){
		this._depixoFile.parentID = depixoFile.parentID || 0
		this._depixoFile.paletteID = depixoFile.paletteID || 1
		this._palette = depixoFile.palette

		if(!!!depixoFile.layers) return

		depixoFile.layers.forEach(layer => {
			const rasterName = `id:${layer.id}`

			const newGroup = new paper.Group({parent: this._importGroup, clipMask: null})
			let importData
			if(layer.data.charAt(0) === '['){
				importData = this._project.importJSON(layer.data, {insert: false})
			}else{
				importData = this._project.importSVG(layer.data, {insert: false})
			}

			newGroup.addChildren(importData.children)
			const raster = newGroup.rasterize()
			raster.name = rasterName
			raster.blendMode = "multiply"

			newGroup.remove()
			this._project.view.update()
		})

		//THIS IS THE WEB WORKER TIMES::::

		// const data = {
		// 	drawing: depixoFile.layers,
		// 	canvas: this.project
		// }
		// this._workerRaster.postMessage(data)
		// this._workerRaster.onmessage = evt => {
		//
		// 	this.project.importJSON(evt.data)
		// 	evt.data.addTo(this.project.activeLayer)
		//
		// 	this.project.activeLayer.addChild(evt.data)
		// }
	}

	getSaveData(){
		//this.org.drawingGroup.visible = true;
		this._depixoFile.layer = this.org.drawingGroup.exportJSON()
		const dataTosend = this._depixoFile
		this.updateUI.saveButton('working')

		fetch("https://alpha.depixo.com/fetch/savedrawing.php",{
      method: 'POST',
      //mode: 'no-cors',
      body: !!dataTosend ? JSON.stringify(dataTosend) : null
    })
      .then(response => {
        if (!response.ok) {
          throw new Error(response.status);
        }
        return response.json();
      })
      .then(data => {
				this._depixoFile.parentID = data.id

				//$('meta[name=og\\:image]').attr('content', '/images/previews/1080/' + currentDepixo.code + '.png');
				window.history.pushState("", "Depixo: " + data.code, '/d/' + data.code);
				//currentDepixo.url = 'https://www.depixo.com/d/' + data.code;
				this.updateUI.saveButton('send')
				console.log(data)
			})
      .catch(error => {
				this.updateUI.saveButton('save')
				console.log(error)
				console.log(dataTosend)
			})

	}

	offScreenCanvas(width , height){
		///Polyfill
		if (!window.OffscreenCanvas) {
		  window.OffscreenCanvas = class OffscreenCanvas {
		    constructor(width, height) {
		      this.canvas = document.createElement("canvas");
		      this.canvas.width = width;
		      this.canvas.height = height;

		      this.canvas.convertToBlob = () => {
		        return new Promise(resolve => {
		          this.canvas.toBlob(resolve);
		        })
		      }

		      return this.canvas;
		    }
	  	}
		}
		return new OffscreenCanvas(width, height)
	}

	//////////////////////////////////////////////////////////////////////////////////////////////
  ////////////                      ////////////////////////////////////////////////////////////
  ////////////  Build HUD Elements  ////////////////////////////////////////////////////////////
  ////////////                      ////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////////

  _buildHUD(hudParent = this._hud){
		hudParent.blendMode='normal'

		//////////////////////////////////////////////////////////////////////////////////////////////
    // Close Warning       ///////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////////////////////

		const closeWarn = new paper.Path.Circle({
			name: 'close-warn',
			parent: hudParent,
			strokeWidth : 3,
			fillColor : null,
			strokeColor: this._hudStyle.color,
			strokeCap: 'round',
			dashArray :[12, 14],
			opacity: 1,
			center: [0,0],
			radius: 50,
			visible: false,
			blendMode: 'normal',
			applyMatrix: false,
		})

		this._project.view.onFrame = evt => {
			// Each frame, rotate:
			if (closeWarn.visible){
				closeWarn.rotate(1)
			}
		}
		//////////////////////////////////////////////////////////////////////////////////////////////
    // Starting Point      ///////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////////////////////

		new paper.Path.Circle({
			name: 'start-point',
			parent: hudParent,
			center: [0,0],
			radius: this._project.currentStyle.strokeWidth / 2,
			fillColor: this._hudStyle.color,
			opacity: .8,
			strokeColor: null,
			strokeWidth: 0,
			visible: false,
			applyMatrix: false,
		})

    //////////////////////////////////////////////////////////////////////////////////////////////
    // Zoom Grid           ///////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////////////////////
    const grid = new paper.Group({
      visible: false,
      name: 'zoom-grid',
      parent: hudParent,
    })

    const spacing = 40
    const width = this._size.width
    const height = this._size.height
    //Horizonal Lines
    for (var h = 0; h < height +1 ; h+=spacing) {
      new paper.Path.Line({
        parent: grid,
        segments: [[0,h],[width,h]],
        fillColor: null,
        strokeColor: this._hudStyle.color,
        strokeWidth: .5,
        opacity: 0.2
      })
    }
    //Vertical Lines
    for (var w = 0; w < width + 1; w+=spacing) {
      new paper.Path.Line({
        parent: grid,
        segments: [[w,0],[w,height]],
        fillColor: null,
        strokeColor: this._hudStyle.color,
        strokeWidth: 0.5,
        opacity: 0.2
      })
    }

    //////////////////////////////////////////////////////////////////////////////////////////////
    // Zoom Indicator      ///////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////////////////////

    const outsideRect = new paper.Path.Rectangle({
      center: this._centerInit,
      size: this._size.add(10),
    })

    const insideRect = new paper.Path.Rectangle({
      center: this._centerInit,
      size: this._size.subtract(40),
    })

    const indicator = outsideRect.subtract(insideRect)

    outsideRect.remove()
    insideRect.remove()

    this._hudZoomIndicator = new paper.Group({
      opacity: 0.3,
      name: 'zoom-indicator',
      parent: hudParent,
      bounds: this._project.view.bounds,
      children: [indicator],
      visible: true,
    })

    //indicator.parent = this._hudZoomIndicator
    indicator.fillColor = this._hudStyle.color
    indicator.strokeColor = null
    indicator.visible = true

  }

}

export default Depixo
